@import "../_variables.scss";

.container {
  position: sticky;
  top: 0;
  z-index: 1;
  left: 0;
  .header_container {
    display: flex;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    height: 7vh;

    .logo {
      margin: 10px;
      height: 6vh;
    }

    .button {
      height: 2.5rem;
      width: auto;
      border: none;
      border-radius: 5px;
      color: white;
      padding: 5px 10px;
      font-weight: 600;
      background-color: $base-color;
    }
  }
  .franchise_container {
    color: white;
    text-align: center;
    z-index: 1;
    background-color: rgb(57, 144, 184);
    padding: 0.7rem 0;
    .underline_text {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
